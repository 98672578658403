
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import CategorySeasonListView from '@/components/views/category/CategorySeasonList.vue'
import { OptionFilterList } from 'types/App'
import { getHistory } from '@/helper/category'

export default Vue.extend<Data, Methods, Computed>({
  name: 'CategorySeasonList',
  components: {
    CategorySeasonListView,
  },
  created() {
    this.fetchData()
  },
  computed: {
    ...mapGetters('ProfileStore', ['getDefaultFilterIndex', 'getFilterList']),
  },
  methods: {
    ...mapActions('CategoryStore', [
      'fetchCategoryHistory',
      'fetchInitCategoryHistory',
      'fetchCategoryMain',
    ]),
    async fetchData() {
      const history: CategoryHistory | null = getHistory()

      if (history && history.page.currentPage === this.$route.path.slice(1)) {
        this.fetchCategoryHistory(history)
      } else {
        this.fetchInitCategoryHistory()
        //스토어 초기화 호출
        this.fetchCategoryMain()
        // default setting
        const { categoryCode } = this.$route.query
        const categoryFilter = this.getDefaultFilterIndex ?? 2
        const { filter } = this.getFilterList[categoryFilter]
        const payload: CategoryHistoryPayload = {
          page: {
            categoryCode: typeof categoryCode === 'string' ? categoryCode : '7',
            categoryFilter,
            sortOption: filter['sort.option'] ?? 'latest',
          },
        }

        this.fetchCategoryHistory(payload)
      }
      localStorage.removeItem('categoryHistory')
    },
  },
})

interface Data {}
interface Methods {
  // Store
  fetchInitCategoryHistory(): Promise<void>
  fetchCategoryHistory(payload: CategoryHistoryPayload): Promise<void>
  fetchCategoryMain(): Promise<void>
  // Component
  fetchData(): void
}
interface Computed {
  getDefaultFilterIndex: number
  getFilterList: OptionFilterList[]
}
