
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import CategoryHeader from '@/components/views/category/common/CategoryHeader.vue'
import CategoryTab from '@/components/views/category/common/CategoryTab.vue'
import CategoryFilter from '@/components/views/category/common/CategoryFilter.vue'
import CategoryProduct from '@/components/views/category/common/CategoryProduct.vue'
import { GetSeasonProduct } from '@/api/displayAPI/CategoryAPI'
import { OptionFilterList, AppProfile } from 'types/App'
import _ from 'lodash'

const PAGE_LIMIT = 50

export default Vue.extend<Data, Methods, Computed>({
  name: 'CategorySeasonListView',
  components: {
    CategoryHeader,
    CategoryTab,
    CategoryFilter,
    CategoryProduct,
  },
  data() {
    return {
      dataFetchComplete: false,
      fixedCategoryCode: this.$route.query.categoryCode,
    }
  },
  async created() {
    const { path } = this.$route
    this.fetchCategoryHistory({ page: { currentPage: path.slice(1) } })
    this.executeGetSeasonHotItemDetail(true)
  },
  watch: {
    async isLogined() {
      try {
        this.fetchLoading({ yn: true })
        const initFirstPage = this.getSearchProduct[0].groupKey ?? 0
        const data = await Promise.all([
          this.getListData(initFirstPage),
          this.getListData(initFirstPage + 1),
          this.getListData(initFirstPage + 2),
        ])

        const list = await this.$infiniteRefreshList(data, initFirstPage)

        const payload: CategoryHistoryPayload = {
          page: {
            searchList: list,
          },
        }

        this.fetchCategoryHistory(payload)
      } finally {
        this.fetchLoading({ yn: false })
      }
    },
  },
  computed: {
    ...mapGetters('ProfileStore', ['getProfile', 'getFilterList']),
    ...mapGetters('CategoryStore', [
      'getSearchProduct',
      'getSeasonHotItemGroup',
      'getCategoryHistory',
    ]),
    history() {
      return this.getCategoryHistory
    },
    emptyCategoryTab() {
      const { categoryTab } = this.history.page
      if (categoryTab !== 0) return true

      const { seasonHotItemList } = this.getSeasonHotItemGroup
      const { query } = this.$route

      const idx = seasonHotItemList.findIndex(
        (item) =>
          item.categoryCode === query.categoryCode &&
          item.seasonHotItemChildList.length > 0
      )

      this.fetchCategoryHistory({
        page: {
          categoryCode:
            idx !== -1
              ? seasonHotItemList[idx].seasonHotItemChildList[0].categoryCode
              : String(query.categoryCode),
        },
      })

      if (idx === -1) return true
      return false
    },
  },
  methods: {
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('CategoryStore', [
      'fetchCategoryHistory',
      'fetchCategoryMain',
    ]),
    async executeGetSeasonHotItemDetail(init) {
      // if (this.getSearchProduct.length > 0)
      //   return (this.dataFetchComplete = true)
      this.fetchLoading({ yn: true })
      this.dataFetchComplete = false
      try {
        const { categoryCode, sortOption, paging } = this.history.page
        const { query } = this.$route
        const { page } = paging
        const params = {
          page,
          limit: PAGE_LIMIT,
          categoryCode: init ? query.categoryCode : categoryCode,
          sortOption,
        }

        await GetSeasonProduct(params)
          .then(({ data }) => {
            const { searchResult } = data
            const { paging } = searchResult.summary
            const searchList: ProductItem[] = this.$replaceList(
              [],
              searchResult.list,
              page,
              PAGE_LIMIT,
              'next'
            )
            this.fetchCategoryHistory({ page: { paging, searchList } })
          })
          .catch((error) => {
            console.log(error)
          })
      } finally {
        this.dataFetchComplete = true
        this.fetchLoading({ yn: false })
      }
    },
    resetList() {
      // infinite scroll 관련 초기화
      const payload: CategoryHistoryPayload = {
        page: {
          marginHeight: 0,
          searchList: [],
        },
      }
      this.fetchCategoryHistory(payload)
    },
    categoryTabChange({ categoryCode }, index) {
      this.resetList()
      const payload: CategoryHistoryPayload = {
        page: {
          categoryTab: index,
          categoryCode,
        },
      }
      this.fetchCategoryHistory(payload)
      this.refreshPaging()
      this.executeGetSeasonHotItemDetail()
    },
    filterTabChange(filterIndex, sortOption) {
      this.resetList()
      const payload: CategoryHistoryPayload = {
        page: {
          categoryFilter: filterIndex,
          sortOption: sortOption['sort.option'],
        },
      }

      this.fetchCategoryHistory(payload)
      //화면위치 초기화
      this.refreshPaging()
      this.executeGetSeasonHotItemDetail()
      this.$getAppHtml.scrollTo({ top: 0 })
    },
    async onPageScrollEvent(page, type) {
      const { categoryCode, sortOption, searchList } = this.history.page
      const params = {
        page,
        limit: PAGE_LIMIT,
        categoryCode,
        sortOption,
      }

      await GetSeasonProduct(params).then((result) => {
        if (!result) return
        const { searchResult } = result.data
        const replaceList = this.$replaceList(
          searchList,
          searchResult.list,
          page,
          PAGE_LIMIT,
          type
        )

        const payload: CategoryHistoryPayload = {
          page: {
            searchList: replaceList,
            paging: searchResult.summary.paging,
          },
        }

        this.fetchCategoryHistory(payload)
      })
    },
    refreshPaging() {
      const payload = {
        page: {
          paging: { total: 0, page: 0, limit: 50 },
        },
      }
      this.fetchCategoryHistory(payload)
    },
    handleRefresh() {
      const { categoryFilter } = this.history.page
      const { filter } = this.getFilterList[categoryFilter]
      const sortOption = filter['sort.option'] ?? 'latest'
      this.fetchCategoryHistory({ page: { categoryFilter, sortOption } })
      this.refreshPaging()
      this.executeGetSeasonHotItemDetail()
    },
    async getListData(page) {
      const { categoryCode, sortOption } = this.history.page
      const params = {
        page,
        limit: PAGE_LIMIT,
        categoryCode,
        sortOption,
      }
      const { data } = await GetSeasonProduct(params)
      return data.searchResult.list
    },
  },
})

interface Data {
  fixedCategoryCode: string | (string | null)[]
  dataFetchComplete: boolean
}
interface Methods {
  // Store
  fetchLoading(payload: { yn: boolean }): Promise<void>
  fetchCategoryHistory(payload: CategoryHistoryPayload): Promise<void>
  fetchCategoryMain(): Promise<void>
  // Component
  executeGetSeasonHotItemDetail(init?: boolean): void
  resetList(): void
  categoryTabChange(tabData: CategorySeasonItem, index: number): void
  filterTabChange(
    filterIndex: number,
    sortOption: { 'sort.option': string }
  ): void
  onPageScrollEvent(page: number, type: string): void
  refreshPaging(): void
  handleRefresh(): void
  getListData(page: number): Promise<ProductItem[]>
}
interface Computed {
  // Profile Store
  getCategoryHistory: CategoryHistory
  history: CategoryHistory
  getFilterList: OptionFilterList[]
  getProfile: AppProfile
  getSearchProduct: ProductItem[]
  getSeasonHotItemGroup: CategorySeason
  emptyCategoryTab: boolean
}
